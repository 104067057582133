import { graphql } from "gatsby";
import React from "react";
import Layout from "../../layouts/Layout";
import SiteMetadata from "../../components/SiteMetadata";

const PrivacyPolicyPage = ({ data }) => (
  <Layout>
    <SiteMetadata title="Privacy Policy" description="Sample description" />

    <div className="bg-gray-100">
      <div className="container py-12 lg:pb-16">
        <div className="flex flex-wrap">
          <div className="w-full pb-8 md:pb-0">
            <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Privacy Policy
            </h1>

            <h2 className="text-xl leading-tight font-semibold tracking-tight text-blue-600 sm:text-2xl">
              Your privacy is our first priority
            </h2>
            <br />
            <div className="mt-4 leading-loose">
              <h1 className="text-xl leading-tight font-semibold tracking-tight sm:text-2xl">Privacy Policy - For Games on Mobile</h1>
              <p>
                Blue Boat doesn't collect any users' data from the games on Mobile except from the following games which contain Facebook SDK:
              </p>
              <ul>
                <li>• Jewel Valley</li>
                <li>• Ore Blast Mania</li>
                <li>• Ore Blitz</li>
                <li>• Sweet Summer World Challenge</li>
                <li>• Just Word Games</li>
              </ul>
              <br />
              <h1 className="text-xl leading-tight font-semibold tracking-tight sm:text-2xl mt-4">Privacy Policy - For Games which contain Facebook SDK</h1>
              <h2 className="text-lg leading-tight font-medium tracking-tight sm:text-xl mt-4">Information Blue Boat Collect</h2>
              <p>
                Blue Boat may access and store some or all of the following information, as allowed by you and your preferences:
              </p>
              <ul>
                <li>• your first and last name</li>
                <li>• your profile picture or its URL</li>
                <li>
                • your user ID number, which is linked to publicly available information such as name and profile photo
                </li>
                <li>
                • the user ID numbers and other public data for your friends
                </li>
              </ul>
              <h2 className="text-lg leading-tight font-medium tracking-tight sm:text-xl mt-4">Use of personal data for marketing purposes</h2>
              <p>
                Blue Boat never sells or hires out the user’s personal data to third parties for marketing purposes.
              </p>
              <h2 className="text-lg leading-tight font-medium tracking-tight sm:text-xl mt-4">Security</h2>
              <p>
                Blue Boat uses standard security practices, including encryption, passwords and physical security measures, to protect your personal data from unauthorized access and disclosure. Your user account information is stored on a secure server with firewall protection. If a user enters sensitive data (such as a credit card number), Blue Boat encrypt that information using secure socket layer technology (SSL).
              </p>
              <h2 className="text-lg leading-tight font-medium tracking-tight sm:text-xl mt-4">Personal data deletion request</h2>
              <p>
                If you do not want us to keep your data on our server any longer. You can contact us directly via our email (support@blueboatgame.com) for data deletion request. The data will be removed as soon as possible.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default PrivacyPolicyPage;